:root {
	--bc-font-sans-serif: 'Nunito', system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	--bc-persiangreen: #00A49B;
	--bc-tarawera: #063852;
	--bc-border-light-grey: #E5E5E5;
	--bc-bg-lighter-grey: #F7F7F7;
	--bc-bg-lightest-grey: #FCFCFC;

	/* Survey JS */
	--sjs-primary-backcolor: var(--bc-persiangreen);
    --sjs-primary-backcolor-dark: var(--bc-persiangreen-dark);
}


.app {
	// background-color: #FAF9F6;
	color: #121212;
	overflow-y: scroll;

	* {
		font-family: var(--bc-font-sans-serif) !important;
		letter-spacing: 0;
	}

	/*
	| -------------------------------------------------------------------------
	| RESET
	| -------------------------------------------------------------------------
	*/

	*, :before, :after {
		box-sizing: border-box;
		outline: none;
	}

	h1, h2, h3, h4, h5, h6, p {
		margin: 0;
		font-size: inherit;
		line-height: inherit;
	}

	a {
		color: var(--bc-persiangreen, inherit);
		transition: all 300ms ease;
		text-decoration: none;
	}
	a:hover,
	a:active {
		text-decoration: underline;
	}

	.logo {
		max-height: 30px;
		width: auto;
	}

	/*
	| -------------------------------------------------------------------------
	| LAYOUT
	| -------------------------------------------------------------------------
	*/

	.bc-global-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		overflow-x: unset;
		position: relative;
		z-index: 1;
	}
	
	/* --------------------------------------------------------------------- */

	.bc-page--header {
		display: block;
		height: 4rem;
		width: 100%;
	}

	/* --------------------------------------------------------------------- */

	.bc-page--message {
		display: none;
		height: 4rem;
		left: 0;
		position: fixed;
		right: 0;
		top: 4rem;
		width: 100%;
		z-index: 1000;
	}
	.show-message .bc-page--message {
		display: block;
	}

	/* --------------------------------------------------------------------- */

	.bc-page--content {
		margin: 4rem 0 0;
		padding: 0 0 56px 0;
		position: relative;
	}
	.show-message .bc-page--content {
		margin-top: 8rem;
	}

	/* --------------------------------------------------------------------- */

	.bc-page--footer {
		border-top: 1px solid var(--bc-bg-lighter-grey, #000);
		padding: 0;
		position: relative;
	}
	
	/* --------------------------------------------------------------------- */

	
	#account-menu a {
		color: inherit !important;
		text-decoration: none !important;
	}

	/*
	| -------------------------------------------------------------------------
	| MuiBottomNavigation
	| -------------------------------------------------------------------------
	*/

	.MuiBottomNavigation-root {
		background-color: var(--bc-persiangreen, #000) !important;
		color: #fff;
	}
	.MuiBottomNavigationAction-root {
		background-color: var(--bc-persiangreen, #000) !important;
		color: #fff !important;
	}


	/*
	| -------------------------------------------------------------------------
	| Schedule Page
	| -------------------------------------------------------------------------
	*/

	.schedule-page {
		background-color: #fff;
		display: flex;
		flex-direction: column;
		min-height: calc( 100vh - 256px );
		padding-top: 1rem;
		padding-bottom: 1rem;
		width: 100%;
		z-index: 1;
	}
	.schedule-page header {
		background-color: #fff;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: flex-end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: flex-end;
		justify-content: flex-end;
		position: -webkit-sticky;
		position: sticky;
		top: -48px;
		width: 100%;
		z-index: 2;
	}
	.show-message .schedule-page header {
		top: 16px;
	}
	@media (min-width: 1200px) {
		.schedule-page header {
			top: -56px;
		}
		.show-message .schedule-page header {
			top: 8px;
		}
	}

	.schedule-page footer {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin: 3rem 0 1.5rem;
	}

	

	/*
	| -------------------------------------------------------------------------
	| 	Job Page
	| -------------------------------------------------------------------------
	*/

	.job-page {
		background-color: #fff;
		display: flex;
		flex-direction: column;
		min-height: calc( 100vh - 256px );
		width: 100%;
		z-index: 1;
	}

	/* --------------------------------------------------------------------- */

	.job-page > section {
		border-bottom: 1px solid var(--bc-border-light-grey, lightgrey);
		padding: 3rem 0;
	}
	.job-page > section:last-of-type {
		border: none;
	}
	
	/* --------------------------------------------------------------------- */

	section.section--job-progress {
		padding: 1rem 0;
	}

	
	/*
	| -------------------------------------------------------------------------
	| 	Job Page - Job Images
	| -------------------------------------------------------------------------
	*/

	.job-images--add-image {
		background-color: var(--bc-bg-lighter-grey, lightgrey);
	}


	/*
	| -------------------------------------------------------------------------
	| 	Job Page - Machine Details
	| -------------------------------------------------------------------------
	*/

	.machine-details {
		font-weight: 500;
		margin: 1rem 0 .5rem;
		position: relative;
		width: 100%;
	}
	
	@media (min-width: 900px) {
		.machine-details {
			display: flex;
			flex-direction: row;
		}
		.machine-details > div {
			flex-grow: 1;
		}
	}

	div + .machine-details {
		border-top: 1px solid var(--bc-bg-lighter-grey, lightgrey);
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.machine-details.machine-details--swapped * {
		opacity: .7;
	}
	.machine-details--swapped:after {
		align-items: center;
		background: rgba(0,0,0,.01);
		bottom: -.5rem;
		color: var(--bc-persiangreen, #000);
		content: '';
		display: flex;
		font-size: 1.5rem;
		font-weight: 700;
		justify-content: center;
		left: -.5rem;
		position: absolute;
		right:  -.5rem;
		text-align: center;
		top: 1rem;
		width: calc( 100% + 1rem );
		z-index: 1000;
	}
	.machine-details--swapped:hover:after {
		content: 'SWAPPED';
	}

	/* --------------------------------------------------------------------- */

	.machine-details--wrapper {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		position: relative;
		width: 100%;
	}
	.machine-details--title {
		padding-right: .5rem;
	}
	.machine-details--text {
		align-items: center;
		display: flex;
		flex-direction: row;
		text-align: right;
	}

	@media screen and (min-width: 600px) {
		.machine-details--wrapper {
			justify-content: flex-start;
		}
		.machine-details--text {
			text-align: left;
		}
	}

	/* --------------------------------------------------------------------- */
	

	/*
	| -------------------------------------------------------------------------
	| 	Job Page - Job Related Files
	| -------------------------------------------------------------------------
	*/

	.job-related-files--wrapper {
		border-top: 1px solid var(--bc-bg-lighter-grey, #000);
		display: block;
		margin-top: 1.5rem;
		padding-top: 1.5rem;
		width: 100%;
	}
	
	.job-related-files--wrapper .text-error {
		color: rgb(219, 68, 55);
	}

	.file-link {
		align-items: center;
		display: flex;
		flex-direction: row;
		margin-bottom: .25rem;
	}
	.file-link span {
		align-items: center;
		display: flex;
		flex-direction: row;
		margin-right: .5rem;
	}


	/*
	| -------------------------------------------------------------------------
	| 	Buttons
	| -------------------------------------------------------------------------
	*/

	.MuiButton-outlined:not(:disabled) {
		border-width: 2px !important;
		padding-top: 6px;
		padding-bottom: 6px;
	}

	.btn-outline-grey {
		border: 1px solid var(--bc-border-light-grey, #000);
		color: inherit;
	}
	.btn-outline-grey:active,
	.btn-outline-grey:hover {
		background: #EEE;
		border-color: var(--bc-border-light-grey, #000);
		color: inherit;
		text-decoration: none;
	}


	/* --------------------------------------------------------------------- */

	.btn-outline-white {
		border: 2px solid #fff;
		color: #fff;
		font-weight: 600;
	}
	.btn-outline-white:active,
	.btn-outline-white:hover {
		background: #fff;
		border: 2px solid #fff;
		color: #121212;
		text-decoration: none;
	}

	/* --------------------------------------------------------------------- */

	.btn-persiangreen-hover {
		background-color: rgb(0, 114, 108) !important;
		color: #fff !important;
	}

	/* --------------------------------------------------------------------- */

	.btn-submit * {
		color: #fff;
	}

	/* --------------------------------------------------------------------- */

	.btn-assessment {
		margin-top: 1rem;
	}
	.btn-assessment:disabled {
		border-color: var(--bc-border-light-grey, #000);
		color: rgba(0, 0, 0, 0.57);
	}
	.btn-assessment:disabled span {
		color: var(--bc-persiangreen, #000);
	}

	/* --------------------------------------------------------------------- */

	.btn-feedback {
		display: none;
	}
	@media (min-width: 900px) {
		.btn-feedback--wrapper {
			z-index: 2147483647;
			opacity: 1;
			transform: rotateZ(-90deg) translateX(50%) translateY(3px);
			border: none !important;
			position: fixed !important;
			inset: auto 0px 50% auto !important;
			width: 146px !important;
			height: 42px !important;
			pointer-events: all !important;
			touch-action: auto !important;
			background: none !important;
			display: block !important;
			color-scheme: none !important;
			border-top-left-radius: 10px !important;
			border-top-right-radius: 10px !important;
			box-shadow: rgba(0, 0, 0, 0.086) 0px -4px 30px -6px, rgba(0, 0, 0, 0.086) 0px -2px 10px 0px !important;
			transform-origin: 100% 100% !important;
			overflow: hidden;
		}
		.btn-feedback--wrapper:hover {
			transform: rotateZ(-90deg) translateX(50%) translateY(0);
		}

		.btn-feedback {			
			width: 100%;
			height: 100%;
			border: none;
			display: flex;
			align-items: center;
			font-weight: 600;
			font-size: 14px;
			transition: background-color 200ms ease-out;
			-moz-transform: rotate(0.01deg);
			outline: none;
			cursor: pointer;
			padding: 5px 10px;
			background-color: #f03e3e;
			color: #ffffff;
			flex-direction: row-reverse;
        }
        .btn-feedback:hover {
          background-color: #e91212;
        }

		.btn-feedback .label {
			flex-grow: 1;
			margin-left: 0;
			margin-right: 10px;
			text-align: center;
			white-space: nowrap;
        }
	
		.btn-feedback .label,
		.btn-feedback .icon-marker {
			pointer-events: none;
			-moz-transform: rotate(0.01deg);
		}
	
		
	}

	/* --------------------------------------------------------------------- */

	.btn-finish-job {
		background: #fff;
		margin-top: 1rem;
	}
	.btn-finish-job:disabled {
		background: rgba(255,255,255,.8);
		border-color: var(--bc-border-light-grey, #000);
		color: rgba(0, 0, 0, 0.57);
	}

	/* --------------------------------------------------------------------- */

	.btnGroup-schedule-filter--week {
		align-items: center;
		background-color: #fff;
		border: 1px solid var(--bc-border-light-grey, #000);
		border-radius: 8px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		height: 44px;
		width: 100%;
		padding: 12px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 700;
		-webkit-letter-spacing: normal;
		-moz-letter-spacing: normal;
		-ms-letter-spacing: normal;
		letter-spacing: normal;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		max-width: 564px;
	}
	.btnGroup-schedule-filter--week > * {
		background-color: transparent !important;
		border: none !important;
		color: #6F6F6F !important;
		padding: 0 !important;
	}
	.btnGroup-schedule-filter--week-text {
		color: #121212 !important;
		font-size: 1rem;
		font-weight: 700;
		text-transform: capitalize;
	}

	/* --------------------------------------------------------------------- */
	
	.btn-survey {
		margin-top: 1rem;
	}
	@media (min-width: 900px) {
		.btn-survey {
			margin-top: 0;
		}
	}


	/*
	| -------------------------------------------------------------------------
	| 	Chip
	| -------------------------------------------------------------------------
	*/

	.bc-chip {
		margin: 0 4px 8px 4px;
	}
	.bc-chip:hover {
		background-color: #fff !important;
		border-color: transparent;
		box-shadow: 0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15);
	}

	.bc-chip .MuiChip-label {
		color: var(--bc-persiangreen, #000);
	
	}
	
	/*
	| -------------------------------------------------------------------------
	| 	Typography - Headings
	| -------------------------------------------------------------------------
	*/

	h1 {
		font-size: 1.875rem;
		font-weight: 900;
		line-height: 2rem;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		gap: 16px;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		min-height: 68px;
	}
	h3 {
		font-size: 1.375rem;
		line-height: 1.625rem;
		font-weight: 900;
	}

	@media (min-width: 1200px) {
		h1 {
			font-size: 2.5rem;
			line-height: 2.75rem;
			font-weight: 900;
		}
		h3 {
			font-size: 1.875rem;
			line-height: 2.4375rem;
			font-weight: 900;
		}
		
	}
	

	/*
	| -------------------------------------------------------------------------
	| 	Nav
	| -------------------------------------------------------------------------
	*/

	.nav-schedule {
		display: block;
		position: relative;
		width: 100%;
	}
	.nav-schedule:before,
	.nav-schedule:after {
		background: #fff;
		content: "";
		display: block;
		left: -1rem;
		min-height: 120px;
		position: absolute;
		top: 0;
		width: 1.5rem;
	}
	.nav-schedule:after {
		left: auto;
		right: -1rem;
	}
	.nav-schedule--grid {
		background-color: #fff;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		gap: 8px;
		margin: 0;
		padding: 1.5rem 0;
		position: relative;
		z-index: 2;
	}

	a.nav-schedule--item-mobile {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border: solid 1px var(--bc-border-light-grey, #000);
		border-radius: 8px;
		cursor: pointer;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		min-height: 72px;
		-webkit-transition: background 400ms ease 0ms;
		transition: background 400ms ease 0ms;
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.nav-schedule--item-mobile--day {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		color: #6F6F6F;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		font-size: 10px;
		line-height: 10px;
		font-weight: 500;
		-webkit-letter-spacing: 0.01px;
		-moz-letter-spacing: 0.01px;
		-ms-letter-spacing: 0.01px;
		letter-spacing: 0.01px;
		text-transform: uppercase;
	}
	.nav-schedule--item-mobile h5 {
		font-size: 18px;
		line-height: 25.2px;
		font-weight: 700;
		-webkit-letter-spacing: normal;
		-moz-letter-spacing: normal;
		-ms-letter-spacing: normal;
		letter-spacing: normal;
	}
	.nav-schedule--item-mobile--date {
		color: #121212;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		line-height: initial;
	}
	.nav-schedule--item-mobile--jobs {
		color: #6F6F6F;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		line-height: initial;
		font-size: 12px;
		line-height: 12px;
		font-weight: 500;
		-webkit-letter-spacing: 0px;
		-moz-letter-spacing: 0px;
		-ms-letter-spacing: 0px;
		letter-spacing: 0px;
	}

	.nav-schedule a {
		color: inherit;
		text-decoration: none;
	}

	@media (min-width: 900px) {
		.nav-schedule a.nav-schedule--item-mobile {
			display: none;
		}
	}

	.nav-schedule a.nav-schedule--item-desktop {
		display: none;
		min-height: 102px;
		padding: 16px 12px;
		border: 1px solid var(--bc-border-light-grey, #000);
		border-radius: 8px;
		cursor: pointer;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		min-height: 72px;
		-webkit-transition: background 400ms ease 0ms;
		transition: background 400ms ease 0ms;
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.nav-schedule a.active {
		border-color: var(--bc-persiangreen, #000)
	}
	.nav-schedule a:hover {
		background: #EEEEEE;
	}
	@media (min-width: 900px) {
		.nav-schedule a.nav-schedule--item-desktop {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
		}
	}
	.nav-schedule--item-desktop--date {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		font-size: 12px;
		line-height: 12px;
		font-weight: 500;
		-webkit-letter-spacing: 0px;
		-moz-letter-spacing: 0px;
		-ms-letter-spacing: 0px;
		letter-spacing: 0px;
		width: 100%;
	}
	.nav-schedule--item-desktop--date-date {
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
	.nav-schedule--item-desktop--date-day-sm {
		display: none;
	}
	.nav-schedule--item-desktop--date-day {
		text-transform: uppercase;
	}
	.nav-schedule--item-desktop--jobs {
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		padding-top: 32px;
		width: 100%;
	}
	.nav-schedule--item-desktop--jobs span {
		color: #6F6F6F;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		line-height: initial;
		font-size: 12px;
		line-height: 12px;
		font-weight: 500;
		-webkit-letter-spacing: 0px;
		-moz-letter-spacing: 0px;
		-ms-letter-spacing: 0px;
		letter-spacing: 0px;
	}
	.nav-schedule--item-desktop--jobs span:first-of-type {
		display: none;
	}
	.nav-schedule--item-desktop--jobs span:last-of-type {
		color: #121212;
		display: initial;
		font-size: 12px;
		line-height: 12px;
		font-weight: 500;
		-webkit-letter-spacing: 0px;
		-moz-letter-spacing: 0px;
		-ms-letter-spacing: 0px;
		letter-spacing: 0px;
		font-weight: 700;
	}

	/* --------------------------------------------------------------------- */

	.schedule-list--title {
		margin-top: 34px;
		margin-bottom: 16px;
	}
	@media (min-width: 900px) {
		.schedule-list--title {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			margin-top: 48px;
		}
		.service-call-list div:first-of-type .schedule-list--title {
			margin-top: 24px;
		}
	}

	/* --------------------------------------------------------------------- */

	.schedule-list {
		margin-top: 24px;
		margin-bottom: 40px;
		width: 100%;
	}
	@media (min-width: 900px) {
		.schedule-list {
			margin-bottom: 32px;
		}
	}

	/* --------------------------------------------------------------------- */

	.schedule-list--heading {
		border-top: 1px solid var(--bc-border-light-grey, #000);
		border-bottom: 1px solid var(--bc-border-light-grey, #000);
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		width: 100%;
	}
	.schedule-list--heading > div {
		display: none;
		flex-grow: 1;
		font-weight: 700;
		line-height: 1.5rem;
    	padding: 1rem;
	}

	.schedule-list--heading div.col-mobile {
		display: block;
		text-align: center;
	}
	@media (min-width: 900px) {
		.schedule-list--heading > div {
			display: block;
		}
		.schedule-list--heading div.col-mobile {
			display: none;
		}
	}

	/* --------------------------------------------------------------------- */

	.schedule-list--row {
		border-bottom: 1px solid var(--bc-border-light-grey, #000);
		color: inherit;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		padding: 1rem 0;
		position: relative;
		text-decoration: none;
		width: 100%;
	}
	.schedule-list--row:nth-child(even) {
		background: rgba(158, 158, 158, 0.03);
	}
	.schedule-list--row:hover {
		background: rgba(158, 158, 158, 0.1);
	}

	.schedule-list--row:before {
		bottom: 0;
		content: "";
		display: block;
		position: absolute;
		top: 0;
		width: 8px;
	}
	.schedule-list--row.highlight-red:before {
		background-color: rgb(219, 68, 55);
	}
	.schedule-list--row.highlight-purple:before {
		background-color: rgb(156, 39, 176);
	}
	.schedule-list--row.highlight-yellow:before {
		background-color: rgb(244, 180, 0);
	}

	.schedule-list--row > div {
		flex-grow: 1;
		line-height: 1.5rem;
    	padding: 0 1rem;
	}

	.schedule-list--row div[class^="col-highlight"] {
		padding-left: 0;
		padding-right: 0;
		width: 8px;
	}


	@media (min-width: 900px) {
		.schedule-list--row {
			flex-direction: row;
		}
		.schedule-list--row > div {
			display: block;
		}
		.schedule-list--row  div[class^="col-highlight"] {
			flex-grow: 0;
			padding: 0;
			width: 8px;
		}
	}

	/* --------------------------------------------------------------------- */
	
	.schedule-list--row .col-time {
		padding-bottom: .75rem;
	}
	.schedule-list--row .col-type {
		display: none;
	}
	.schedule-list--row .col-contact,
	.schedule-list--row .col-misc > div {
		font-size: .875rem;
		line-height: 1.25rem;
		padding-top: .75rem;
	}
	.schedule-list--row .col-misc > div > span {
		margin-right: .5rem;
	}

	@media (min-width: 900px) {
		.schedule-list--heading .col-time,
		.schedule-list--row .col-time {
			max-width: 90px;
			padding-bottom: 0;
			padding-left: 1.5rem;
			text-align: center;
		}
		.schedule-list--row .col-time span {
			display: none;
		}
		.schedule-list--row .col-type {
			display: block;
		}
		.schedule-list--row .col-contact {
			font-size: 1rem;
			line-height: 1.5rem;
			padding-top: 0;
		}
		.schedule-list--row .col-contact > div {
			font-size: .875rem;
		}
		.schedule-list--row .col-misc > div {
			
			line-height: 1.25rem;
			padding-top: 0;
		}
		.schedule-list--heading .col-type,
		.schedule-list--row .col-type,
		.schedule-list--heading .col-venue,
		.schedule-list--row .col-venue,
		.schedule-list--heading .col-contact,
		.schedule-list--row .col-contact,
		.schedule-list--heading .col-misc,
		.schedule-list--row .col-misc {
			width: calc(25% - 15px);
		}
		.schedule-list--heading .col-type,
		.schedule-list--row .col-type,
		.schedule-list--heading .col-venue,
		.schedule-list--row .col-venue {
			min-width: 180px;
		}
	}

	/* --------------------------------------------------------------------- */

	.schedule-table {
		border-radius: 0;
	}
	.schedule-table th {
		border-top: 1px solid var(--bc-border-light-grey, #000);
		border-bottom: 1px solid var(--bc-border-light-grey, #000);
		font-size: 1rem !important;
		font-weight: 700 !important;
	}
	.schedule-table thead th:first-of-type {
		padding: 16px 0 !important;
		width: 8px !important;
	}
	.schedule-table tbody .rowlink:nth-child(odd) {
		background: rgba(158, 158, 158, 0.03);
	}
	.schedule-table tbody .rowlink td.row-highlight {
		padding: 16px 0 !important;
		width: 8px !important;
	}
	.schedule-table tbody .rowlink td.row-red {
		background-color: rgb(219, 68, 55);
	}
	.schedule-table tbody .rowlink td.row-purple {
		background-color: rgb(156, 39, 176);
	}
	.schedule-table tbody .rowlink td.row-yellow {
		background-color: rgb(244, 180, 0);
	}
	.schedule-table tbody .rowlink:hover {
		background: rgba(158, 158, 158, 0.1);
		cursor: pointer;
	}
	.schedule-table td {
		font-size: 1rem !important;
		vertical-align: top;
	}
	
	/* --------------------------------------------------------------------- */

	.notes-table thead th {
		background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
		font-weight: 700;
	}

	/*
	| -------------------------------------------------------------------------
	| Breadcrumbs
	| -------------------------------------------------------------------------
	*/

	.breadcrumbs {
		background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
		padding: 1rem 0;
	}


	/*
	| -------------------------------------------------------------------------
	| Cards
	| -------------------------------------------------------------------------
	*/

	.card-notes {
		background-color: var(--bc-bg-lightest-grey, #FCFCFC);
		margin-top: 1.5rem;
		transition: all 300ms ease;
	}
	.card-notes:hover {
		background-color: var(--bc-persiangreen, #f7f7f7);
	}
	.card-notes:hover * {
		color: #fff;
	}

	.card-notes--heading {
		font-size: 1.125rem;
		font-weight: 700;
		text-transform: capitalize;
	}
	.card-notes--body {
		font-size: 1rem;
		margin: 0 0 1rem;
	}
	.card-notes--meta {
		color: #6F6F6F;
		font-size: .875rem;
		font-weight: 500;
	}
	.card-notes--meta-user {
		color: var(--bc-persiangreen, #6F6F6F);
		text-align: left;
	}
	@media (min-width: 900px) {
		.card-notes--meta-user {
			text-align: right;
		}
	}


	/*
	| -------------------------------------------------------------------------
	| Flex
	| -------------------------------------------------------------------------
	*/

	.flex {
		display: flex;
	}

	/* --------------------------------------------------------------------- */

	.flex-col {
		flex-direction: column;
	}
	.flex-row {
		flex-direction: row;
	}


	/* --------------------------------------------------------------------- */

	.basis-1_12 {
		flex-basis: 8.3333333333%;
	}
	.basis-2_12 {
		flex-basis: 16.6666666667%;
	}
	.basis-3_12 {
		flex-basis: 25%;
	}
	.basis-4_12 {
		flex-basis: 33.3333333333%;
	}
	.basis-5_12 {
		flex-basis: 41.6666666667%;
	}
	.basis-6_12 {
		flex-basis: 50%;
	}
	.basis-7_12 {
		flex-basis: 58.3333333333%;
	}
	.basis-8_12 {
		flex-basis: 66.6666666667%;
	}
	.basis-9_12 {
		flex-basis: 75%;
	}
	.basis-10_12 {
		flex-basis: 83.3333333333%;
	}
	.basis-11_12 {
		flex-basis: 91.6666666667%;
	}


	/* --------------------------------------------------------------------- */

	.items-center {
		align-items: center;
	}
	.items-end {
		align-items: flex-end;
	}
	.items-start {
		align-items: flex-start;
	}

	/* --------------------------------------------------------------------- */

	.justify-content-around {
		justify-content: space-around;
	}
	.justify-content-between {
		justify-content: space-between;
	}
	.justify-content-center {
		justify-content: center;
	}
	.justify-content-end {
		justify-content: end;
	}
	.justify-content-evenly {
		justify-content: space-evenly;
	}
	.justify-content-start {
		justify-content: start;
	}
	.justify-content-stretch {
		justify-content: stretch;
	}


	/*
	| -------------------------------------------------------------------------
	| Drawer
	| -------------------------------------------------------------------------
	*/

	.drawer {
		background-color: #FAF9F6;
		background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		width: 100vw;
	}
	.drawer .header {
		align-items: center;
		border-radius: 0;
		display: flex;
		height: 64px;
		padding: 0 1.5rem;
		width: 100%;
	}
	.drawer footer {
		background: #fff;
		border-top: 1px solid rgba(0, 0, 0, 0.175);
	}

	/* --------------------------------------------------------------------- */

	.font-smaller {
		font-size: .875rem;
	}

	.text-muted {
		color: rgba(0,0,0,.525);
	}


	.call-info > div {
		display: flex;
		gap: 8px;
	}
	.call-info > div > span:first-of-type{
		width: 100px;
	}

	/* --------------------------------------------------------------------- */

	.input-not-disabled {
		background-color: #fff;
		color: rgba(0, 0, 0, 0.87);
		-webkit-text-fill-color: rgba(0, 0, 0, 0.87);
		transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	}
	.input-not-disabled input {
		color: rgba(0, 0, 0, 0.87);
		-webkit-text-fill-color: rgba(0, 0, 0, 0.87);
	}


	/*
	| -------------------------------------------------------------------------
	| Assessments
	| -------------------------------------------------------------------------
	*/

	.assessments-wrapper {
		background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
		margin: 1rem 0 0;
		padding: 1rem 0;
		position: relative;
	}
	.assessments-wrapper:before,
	.assessments-wrapper:after {
		background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
		bottom: 0;
		content: "";
		display: block;
		left: -1rem;
		height: 100%;
		position: absolute;
		top: 0;
		width: 1rem;
	}
	.assessments-wrapper:after {
		left: auto;
		right: -1rem;
	}
	@media (min-width: 900px) {
		.assessments-wrapper {
			border-radius: 4px;
			padding: .25rem;
		}
		.assessments-wrapper:before,
		.assessments-wrapper:after {
			content: none;
		}
	}

	.assessments-wrapper button {
		padding: 1rem;
	}

	.machine-info--wrapper {
		margin: 1rem 0 0;
		padding: 0 1rem;
	}
	
	@media (min-width: 900px) {
		.assessments-row:after  {
			content: none;
			margin: 0;
		}
	}
	


	/*
	| -------------------------------------------------------------------------
	| Survey JS
	| -------------------------------------------------------------------------
	*/

	.sd-root-modern.sd-root-modern--full-container {
		overflow-x: visible;
	}

	.sd-root-modern form {
		position: relative;
		z-index: 0;
	}

	.sd-header__text .sd-title {
		font-size: 1.375rem;
		line-height: 1;
	}
	.sd-title.sd-container-modern__title {
		border-bottom: 2px solid var(--sjs-primary-backcolor, var(--primary, #19b394));
		box-shadow: none;
	}

	.sd-root-modern--mobile .sd-body.sd-body--responsive, .sd-root-modern--mobile .sd-body.sd-body--static {
		padding: 1.5rem 0 0 0;
	}

	.sd-completedpage:after {
		height: calc(13*(var(--sjs-base-unit, var(--base-unit, 8px))));
	}
	@media (min-width: 900px) {
		.sd-title.sd-container-modern__title {
			padding: 1.5rem;
		}
		.sd-body.sd-body--responsive {
			padding: 1.5rem;
		}   
	}

	/*
	| -------------------------------------------------------------------------
	| Typography
	| -------------------------------------------------------------------------
	*/

	hr.page-divider {
		border: 0;
		background-color: var(--bc-border-light-grey, #000);
		display: block;
		height: 1px;
		margin: 4rem 0 3rem;
		overflow: visible;
		position: relative;
		width: 100%;
	}

	.text-capitalize {
		text-transform: capitalize;
	}

	.truncated-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/*
	| -------------------------------------------------------------------------
	| Message Bars
	| -------------------------------------------------------------------------
	*/

	.message-bar__content {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.message-bar__content > div {
		align-items: center;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
	.message-bar__icon {
		padding-right: 1rem;
	}
	.message-bar__text {
		flex-grow: 1;
		font-size: inherit;
	}
	.message-bar__actions {
		font-size: inherit;
		flex-grow: 0;
	}
	@media (min-width: 1200px) {
		.message-bar__text {
			font-size: 1.125rem;
			line-height: 2rem;
		}
	}

	/* --------------------------------------------------------------------- */

	.offline-bar {
		align-items: center;
		background: linear-gradient(to right, #db4437, #ff314a);
		color: #fff;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		box-sizing: border-box;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		height: 4rem;
		justify-items: center;
		position: relative;
		width: 100%;
	}

	/* --------------------------------------------------------------------- */

	.syncData-bar {
		align-items: center;
		background: linear-gradient(to right, #db4437, #ff314a);
		color: #fff;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		box-sizing: border-box;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		height: 4rem;
		justify-items: center;
		position: relative;
		width: 100%;
	}
	
	
	/*
	| -------------------------------------------------------------------------
	| Dialogs
	| -------------------------------------------------------------------------
	*/

	.MuiDialog-container svg {
		color: #999;
	}

	/* --------------------------------------------------------------------- */

	.MuiDialogTitle-root {
		border-bottom: 2px solid var(--bc-persiangreen, #000);
		display: flex;
		flex-direction: row;
		font-size: 1.25rem;
		font-weight: 700;
		line-height: 28px;
		-webkit-box-pack: space-between;
		-ms-flex-pack: space-between;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		padding: 12px;
	}
	.MuiDialogTitle-root div {
		padding-top: 2px;
	}

	/* --------------------------------------------------------------------- */

	.MuiDialogContent-root {
		background: #f7f8f9;
		padding: 12px !important;
	}

	/* --------------------------------------------------------------------- */


	/*
	| -------------------------------------------------------------------------
	| Dialogs - Feedback
	| -------------------------------------------------------------------------
	*/

	.dialog-feedback .MuiDialog-paper {
		max-width: 396px;
		width: 100%;
	}

	.dialog-feedback .MuiDialogTitle-root {
		border-bottom: 2px solid var(--bc-persiangreen, #000);
		display: flex;
		flex-direction: row;
		font-size: 1.25rem;
		font-weight: 700;
		line-height: 28px;
		-webkit-box-pack: space-between;
		-ms-flex-pack: space-between;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		padding: 12px;
	}
	.dialog-feedback .MuiDialogTitle-root div {
		padding-top: 2px;
	}

	.dialog-feedback .MuiDialogContent-root {
		background: #f7f8f9;
		padding: 12px;
		overflow-y: auto;
	}
	.dialog-feedback svg {
		color: #999;
	}
	.dialog-feedback .form-label {
		color: #212121;
		font-size: 0.875rem;
		font-weight: 600;
		line-height: 1.5rem;
		padding-bottom: .25rem;
	}

	#feedbackDescription,
	#feedbackTitle,
	#feedbackType {
		font-size: 0.875rem;
	}
	#feedbackDescription {
		line-height: 1.5em;
	}

	@media (min-width: 900px) {
		.dialog-feedback .MuiDialog-container {
			-webkit-box-align: end;
			-ms-flex-align: end;
			align-items: end;
			-webkit-box-pack: end;
			-ms-flex-pack: end;
			-webkit-justify-content: end;
			justify-content: end;
		}	
	}

	/*
	| -------------------------------------------------------------------------
	| Dialogs - Swap Machine
	| -------------------------------------------------------------------------
	*/
	
	.swap-machine-dialog .MuiDialog-paper {
		overflow-y: visible;
	}

	/* --------------------------------------------------------------------- */

	.swap-machine-dialog .MuiDialogActions-root {
		background-color: rgba(219, 68, 55, .1);
		border-top: 1px solid rgb(219, 68, 55);
		color: rgb(219, 68, 55);
		font-weight: 600;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		padding: 12px;
	}


	/*
	| -------------------------------------------------------------------------
	|	Loading
	| -------------------------------------------------------------------------
	*/

	.loading-fullscreen {
		align-items: center;
		background-color: rgba(0,0,0,.5);
		color: var(--bc-persiangreen, #000);
		display: flex;
		font-size: 4rem;
		height: 100vh;
		justify-content: center;
		left: 0;
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 1900;
	}

	/*
	| -------------------------------------------------------------------------
	|	Loading - Ellipsis
	| -------------------------------------------------------------------------
	*/
	.loading-ellipsis {
		width: 100px;
	}
	.loading-ellipsis:after {
		overflow: hidden;
		display: inline-block;
		vertical-align: bottom;
		-webkit-animation: ellipsis steps(4,end) 900ms infinite;      
		animation: ellipsis steps(4,end) 900ms infinite;
		content: "\2026"; /* ascii code for the ellipsis character */
		width: 0px;
	  }
	  
	  @keyframes ellipsis {
		to {
		  width: 1.25em;    
		}
	  }
	  
	  @-webkit-keyframes ellipsis {
		to {
		  width: 1.25em;    
		}
	  }

}