:root {
  --bc-font-sans-serif: "Nunito", system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bc-persiangreen: #00A49B;
  --bc-persiangreen-dark: #00837c;
  --bc-tarawera: #063852;
}

/*
| -------------------------------------------------------------------------
| RESET
| -------------------------------------------------------------------------
*/
body.auth {
  background-color: #f5f7f9;
  color: #484848;
  font-family: var(--bc-font-sans-serif);
  height: 100%;
  line-height: 1.5;
}

.auth {
  /*
  | -------------------------------------------------------------------------
  | TYPOGRAPHY
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | ALERTS
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | BUTTONS
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | CARDS
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | FORMS
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
}
.auth .content {
  display: block;
  padding: 3rem 0;
}
.auth a {
  color: var(--bc-persiangreen-dark);
  font-weight: 600;
  text-decoration: none;
  transition: all 300ms ease;
}
.auth a:hover {
  color: #484848;
  -webkit-text-decoration: solid;
          text-decoration: solid;
}
.auth .subtext {
  font-size: 0.875rem;
}
.auth .alert {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  color: inherit;
  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
}
.auth .alert-danger {
  background-color: #f8d7da;
  border-color: #f5c2c7;
  color: #842029;
}
.auth .alert-success {
  background-color: #d1e7dd;
  border-color: #badbcc;
  color: #0f5132;
}
.auth .btn {
  border: 2px solid transparent;
  border-radius: 0.25rem;
  box-shadow: none !important;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  outline: none !important;
  padding: 16px 14px;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
.auth .btn-block {
  width: 100%;
}
.auth .btn-primary {
  background-color: var(--bc-persiangreen);
  border-color: var(--bc-persiangreen);
  color: #fff;
}
.auth .btn-primary:hover {
  background-color: var(--bc-persiangreen-dark);
  border-color: var(--bc-persiangreen-dark);
  color: #fff;
}
.auth .bc--page--card-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}
.auth .bc--card {
  background-color: white;
  border-radius: 1rem;
  box-shadow: rgb(229, 231, 235) 0 4px 8px 4px;
  display: flex;
  flex-direction: column;
  margin: 5rem 0 0;
  padding: 2rem;
  max-width: 540px;
  width: 100%;
}
.auth .bc--card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.auth .bc--card-title .subtitle {
  font-size: 1rem;
  font-weight: 400;
}
.auth .bc--card-form {
  text-align: left;
}
@media screen and (min-width: 600px) {
  .auth .bc--card {
    padding: 3.5rem;
    width: 540px;
  }
}
.auth div.input-block {
  margin-bottom: 1rem;
  position: relative;
}
.auth div.input-block:hover input {
  border-color: #484848;
}
.auth div.input-block:hover label.placeholder {
  color: #484848;
}
.auth div.input-block input:valid + label.placeholder,
.auth div.input-block input:focus + label.placeholder {
  transform: scale(0.8) translateY(-30px);
  background: #fff;
}
.auth div.input-block input:focus {
  border-color: var(--bc-persiangreen, #000);
}
.auth div.input-block input:focus + label.placeholder {
  color: var(--bc-persiangreen, #000);
  font-weight: 500;
}
.auth .Mui-focused {
  border-color: var(--bc-persiangreen);
}
.auth .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--bc-persiangreen);
}/*# sourceMappingURL=auth_v1.css.map */