@charset "UTF-8";
:root {
  --bc-font-sans-serif: "Nunito", system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bc-persiangreen: #00A49B;
  --bc-tarawera: #063852;
  --bc-border-light-grey: #E5E5E5;
  --bc-bg-lighter-grey: #F7F7F7;
  --bc-bg-lightest-grey: #FCFCFC;
  /* Survey JS */
  --sjs-primary-backcolor: var(--bc-persiangreen);
  --sjs-primary-backcolor-dark: var(--bc-persiangreen-dark);
}

.app {
  color: #121212;
  overflow-y: scroll;
  /*
  | -------------------------------------------------------------------------
  | RESET
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | LAYOUT
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /*
  | -------------------------------------------------------------------------
  | MuiBottomNavigation
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | Schedule Page
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | 	Job Page
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /*
  | -------------------------------------------------------------------------
  | 	Job Page - Job Images
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | 	Job Page - Machine Details
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /*
  | -------------------------------------------------------------------------
  | 	Job Page - Job Related Files
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | 	Buttons
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /*
  | -------------------------------------------------------------------------
  | 	Chip
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | 	Typography - Headings
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | 	Nav
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /*
  | -------------------------------------------------------------------------
  | Breadcrumbs
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | Cards
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | Flex
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /*
  | -------------------------------------------------------------------------
  | Drawer
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /*
  | -------------------------------------------------------------------------
  | Assessments
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | Survey JS
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | Typography
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | Message Bars
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /*
  | -------------------------------------------------------------------------
  | Dialogs
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /* --------------------------------------------------------------------- */
  /*
  | -------------------------------------------------------------------------
  | Dialogs - Feedback
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  | Dialogs - Swap Machine
  | -------------------------------------------------------------------------
  */
  /* --------------------------------------------------------------------- */
  /*
  | -------------------------------------------------------------------------
  |	Loading
  | -------------------------------------------------------------------------
  */
  /*
  | -------------------------------------------------------------------------
  |	Loading - Ellipsis
  | -------------------------------------------------------------------------
  */
}
.app * {
  font-family: var(--bc-font-sans-serif) !important;
  letter-spacing: 0;
}
.app *, .app :before, .app :after {
  box-sizing: border-box;
  outline: none;
}
.app h1, .app h2, .app h3, .app h4, .app h5, .app h6, .app p {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
}
.app a {
  color: var(--bc-persiangreen, inherit);
  transition: all 300ms ease;
  text-decoration: none;
}
.app a:hover,
.app a:active {
  text-decoration: underline;
}
.app .logo {
  max-height: 30px;
  width: auto;
}
.app .bc-global-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: unset;
  position: relative;
  z-index: 1;
}
.app .bc-page--header {
  display: block;
  height: 4rem;
  width: 100%;
}
.app .bc-page--message {
  display: none;
  height: 4rem;
  left: 0;
  position: fixed;
  right: 0;
  top: 4rem;
  width: 100%;
  z-index: 1000;
}
.app .show-message .bc-page--message {
  display: block;
}
.app .bc-page--content {
  margin: 4rem 0 0;
  padding: 0 0 56px 0;
  position: relative;
}
.app .show-message .bc-page--content {
  margin-top: 8rem;
}
.app .bc-page--footer {
  border-top: 1px solid var(--bc-bg-lighter-grey, #000);
  padding: 0;
  position: relative;
}
.app #account-menu a {
  color: inherit !important;
  text-decoration: none !important;
}
.app .MuiBottomNavigation-root {
  background-color: var(--bc-persiangreen, #000) !important;
  color: #fff;
}
.app .MuiBottomNavigationAction-root {
  background-color: var(--bc-persiangreen, #000) !important;
  color: #fff !important;
}
.app .schedule-page {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /*min-height: calc(100vh - 256px);*/
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  z-index: 1;
}
.app .schedule-page header {
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  position: sticky;
  top: -48px;
  width: 100%;
  z-index: 2;
}
.app .show-message .schedule-page header {
  top: 16px;
}
@media (min-width: 1200px) {
  .app .schedule-page header {
    top: -56px;
  }
  .app .show-message .schedule-page header {
    top: 8px;
  }
}
.app .schedule-page footer {
  display: flex;
  justify-content: center;
  margin: 3rem 0 1.5rem;
}
.app .job-page {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 256px);
  width: 100%;
  z-index: 1;
}
.app .job-page > section {
  border-bottom: 1px solid var(--bc-border-light-grey, lightgrey);
  padding: 3rem 0;
}
.app .job-page > section:last-of-type {
  border: none;
}
.app section.section--job-progress {
  padding: 1rem 0;
}
.app .job-images--add-image {
  background-color: var(--bc-bg-lighter-grey, lightgrey);
}
.app .machine-details {
  font-weight: 500;
  margin: 1rem 0 0.5rem;
  position: relative;
  width: 100%;
}
@media (min-width: 900px) {
  .app .machine-details {
    display: flex;
    flex-direction: row;
  }
  .app .machine-details > div {
    flex-grow: 1;
  }
}
.app div + .machine-details {
  border-top: 1px solid var(--bc-bg-lighter-grey, lightgrey);
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}
.app .machine-details.machine-details--swapped * {
  opacity: 0.7;
}
.app .machine-details--swapped:after {
  align-items: center;
  background: rgba(0, 0, 0, 0.01);
  bottom: -0.5rem;
  color: var(--bc-persiangreen, #000);
  content: "";
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
  justify-content: center;
  left: -0.5rem;
  position: absolute;
  right: -0.5rem;
  text-align: center;
  top: 1rem;
  width: calc(100% + 1rem);
  z-index: 1000;
}
.app .machine-details--swapped:hover:after {
  content: "Removed from venue";
}
.app .machine-details--wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.app .machine-details--title {
  padding-right: 0.5rem;
}
.app .machine-details--text {
  align-items: center;
  display: flex;
  flex-direction: row;
  text-align: right;
}
@media screen and (min-width: 600px) {
  .app .machine-details--wrapper {
    justify-content: flex-start;
  }
  .app .machine-details--text {
    text-align: left;
  }
}
.app .job-related-files--wrapper {
  border-top: 1px solid var(--bc-bg-lighter-grey, #000);
  display: block;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  width: 100%;
}
.app .job-related-files--wrapper .text-error {
  color: rgb(219, 68, 55);
}
.app .file-link {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.25rem;
}
.app .file-link span {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 0.5rem;
}
.app .MuiButton-outlined:not(:disabled) {
  border-width: 2px !important;
  padding-top: 6px;
  padding-bottom: 6px;
}
.app .btn-outline-grey {
  border: 1px solid var(--bc-border-light-grey, #000);
  color: inherit;
}
.app .btn-outline-grey:active,
.app .btn-outline-grey:hover {
  background: #EEE;
  border-color: var(--bc-border-light-grey, #000);
  color: inherit;
  text-decoration: none;
}
.app .btn-outline-white {
  border: 2px solid #fff;
  color: #fff;
  font-weight: 600;
}
.app .btn-outline-white:active,
.app .btn-outline-white:hover {
  background: #fff;
  border: 2px solid #fff;
  color: #121212;
  text-decoration: none;
}
.app .btn-persiangreen-hover {
  background-color: rgb(0, 114, 108) !important;
  color: #fff !important;
}
.app .btn-submit * {
  color: #fff;
}
.app .btn-assessment {
  margin-top: 1rem;
}
.app .btn-assessment:disabled {
  border-color: var(--bc-border-light-grey, #000);
  color: rgba(0, 0, 0, 0.57);
}
.app .btn-assessment:disabled span {
  color: var(--bc-persiangreen, #000);
}
.app .btn-feedback {
  display: none;
}
@media (min-width: 900px) {
  .app .btn-feedback--wrapper {
    z-index: 2147483647;
    opacity: 1;
    transform: rotateZ(-90deg) translateX(50%) translateY(3px);
    border: none !important;
    position: fixed !important;
    inset: auto 0px 50% auto !important;
    width: 146px !important;
    height: 42px !important;
    pointer-events: all !important;
    touch-action: auto !important;
    background: none !important;
    display: block !important;
    color-scheme: none !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.086) 0px -4px 30px -6px, rgba(0, 0, 0, 0.086) 0px -2px 10px 0px !important;
    transform-origin: 100% 100% !important;
    overflow: hidden;
  }
  .app .btn-feedback--wrapper:hover {
    transform: rotateZ(-90deg) translateX(50%) translateY(0);
  }
  .app .btn-feedback {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    transition: background-color 200ms ease-out;
    -moz-transform: rotate(0.01deg);
    outline: none;
    cursor: pointer;
    padding: 5px 10px;
    background-color: #f03e3e;
    color: #ffffff;
    flex-direction: row-reverse;
  }
  .app .btn-feedback:hover {
    background-color: #e91212;
  }
  .app .btn-feedback .label {
    flex-grow: 1;
    margin-left: 0;
    margin-right: 10px;
    text-align: center;
    white-space: nowrap;
  }
  .app .btn-feedback .label,
  .app .btn-feedback .icon-marker {
    pointer-events: none;
    -moz-transform: rotate(0.01deg);
  }
}
.app .btn-finish-job {
  background: #fff;
  margin-top: 1rem;
}
.app .btn-finish-job:disabled {
  background: rgba(255, 255, 255, 0.8);
  border-color: var(--bc-border-light-grey, #000);
  color: rgba(0, 0, 0, 0.57);
}
.app .btnGroup-schedule-filter--week {
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--bc-border-light-grey, #000);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  height: 44px;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  max-width: 564px;
}
.app .btnGroup-schedule-filter--week > * {
  background-color: transparent !important;
  border: none !important;
  color: #6F6F6F !important;
  padding: 0 !important;
}
.app .btnGroup-schedule-filter--week-text {
  color: #121212 !important;
  font-size: 1rem;
  font-weight: 700;
  text-transform: capitalize;
}
.app .btn-survey {
  margin-top: 1rem;
}
@media (min-width: 900px) {
  .app .btn-survey {
    margin-top: 0;
  }
}
.app .bc-chip {
  margin: 0 4px 8px 4px;
}
.app .bc-chip:hover {
  background-color: #fff !important;
  border-color: transparent;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}
.app .bc-chip .MuiChip-label {
  color: var(--bc-persiangreen, #000);
}
.app h1 {
  font-size: 1.875rem;
  font-weight: 900;
  line-height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  flex: 1;
  min-height: 68px;
}
.app h3 {
  font-size: 1.375rem;
  line-height: 1.625rem;
  font-weight: 900;
}
@media (min-width: 1200px) {
  .app h1 {
    font-size: 2.5rem;
    line-height: 2.75rem;
    font-weight: 900;
  }
  .app h3 {
    font-size: 1.875rem;
    line-height: 2.4375rem;
    font-weight: 900;
  }
}
.app .nav-schedule {
  display: block;
  position: relative;
  width: 100%;
}
.app .nav-schedule:before,
.app .nav-schedule:after {
  background: #fff;
  content: "";
  display: block;
  left: -1rem;
  min-height: 120px;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.app .nav-schedule:after {
  left: auto;
  right: -1rem;
}
.app .nav-schedule--grid {
  background-color: #fff;
  display: flex;
  gap: 8px;
  margin: 0;
  padding: 1.5rem 0;
  position: relative;
  z-index: 2;
}
.app a.nav-schedule--item-mobile {
  display: flex;
  border: solid 1px var(--bc-border-light-grey, #000);
  border-radius: 8px;
  cursor: pointer;
  flex: 1;
  min-height: 72px;
  transition: background 400ms ease 0ms;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.app .nav-schedule--item-mobile--day {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6F6F6F;
  flex: 1;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  -webkit-letter-spacing: 0.01px;
  -moz-letter-spacing: 0.01px;
  -ms-letter-spacing: 0.01px;
  letter-spacing: 0.01px;
  text-transform: uppercase;
}
.app .nav-schedule--item-mobile h5 {
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 700;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
}
.app .nav-schedule--item-mobile--date {
  color: #121212;
  flex: 1;
  line-height: initial;
}
.app .nav-schedule--item-mobile--jobs {
  color: #6F6F6F;
  flex: 1;
  line-height: initial;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
}
.app .nav-schedule a {
  color: inherit;
  text-decoration: none;
}
@media (min-width: 900px) {
  .app .nav-schedule a.nav-schedule--item-mobile {
    display: none;
  }
}
.app .nav-schedule a.nav-schedule--item-desktop {
  display: none;
  min-height: 102px;
  padding: 16px 12px;
  border: 1px solid var(--bc-border-light-grey, #000);
  border-radius: 8px;
  cursor: pointer;
  flex: 1;
  min-height: 72px;
  transition: background 400ms ease 0ms;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.app .nav-schedule a.active {
  border-color: var(--bc-persiangreen, #000);
}
.app .nav-schedule a:hover {
  background: #EEEEEE;
}
@media (min-width: 900px) {
  .app .nav-schedule a.nav-schedule--item-desktop {
    display: flex;
  }
}
.app .nav-schedule--item-desktop--date {
  display: flex;
  flex: 1;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  width: 100%;
}
.app .nav-schedule--item-desktop--date-date {
  flex: 1;
}
.app .nav-schedule--item-desktop--date-day-sm {
  display: none;
}
.app .nav-schedule--item-desktop--date-day {
  text-transform: uppercase;
}
.app .nav-schedule--item-desktop--jobs {
  align-items: center;
  display: flex;
  flex: 1;
  padding-top: 32px;
  width: 100%;
}
.app .nav-schedule--item-desktop--jobs span {
  color: #6F6F6F;
  flex: 1;
  line-height: initial;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
}
.app .nav-schedule--item-desktop--jobs span:first-of-type {
  display: none;
}
.app .nav-schedule--item-desktop--jobs span:last-of-type {
  color: #121212;
  display: initial;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  font-weight: 700;
}
.app .schedule-list--title {
  margin-top: 34px;
  margin-bottom: 16px;
}
@media (min-width: 900px) {
  .app .schedule-list--title {
    display: flex;
    margin-top: 48px;
  }
  .app .service-call-list div:first-of-type .schedule-list--title {
    margin-top: 24px;
  }
}
.app .schedule-list {
  margin-top: 24px;
  margin-bottom: 40px;
  width: 100%;
}
@media (min-width: 900px) {
  .app .schedule-list {
    margin-bottom: 32px;
  }
}
.app .schedule-list--heading {
  border-top: 1px solid var(--bc-border-light-grey, #000);
  border-bottom: 1px solid var(--bc-border-light-grey, #000);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}
.app .schedule-list--heading > div {
  display: none;
  flex-grow: 1;
  font-weight: 700;
  line-height: 1.5rem;
  padding: 1rem;
}
.app .schedule-list--heading div.col-mobile {
  display: block;
  text-align: center;
}
@media (min-width: 900px) {
  .app .schedule-list--heading > div {
    display: block;
  }
  .app .schedule-list--heading div.col-mobile {
    display: none;
  }
}
.app .schedule-list--row {
  border-bottom: 1px solid var(--bc-border-light-grey, #000);
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 1rem 0;
  position: relative;
  text-decoration: none;
  width: 100%;
}
.app .schedule-list--row:nth-child(even) {
  background: rgba(158, 158, 158, 0.03);
}
.app .schedule-list--row:hover {
  background: rgba(158, 158, 158, 0.1);
}
.app .schedule-list--row:before {
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 8px;
}
.app .schedule-list--row.highlight-red:before {
  background-color: rgb(219, 68, 55);
}
.app .schedule-list--row.highlight-blue:before {
  background-color: rgb(33, 150, 243); /* A cool, vibrant blue */
}
.app .schedule-list--row.highlight-purple:before {
  background-color: rgb(156, 39, 176);
}
.app .schedule-list--row.highlight-yellow:before {
  background-color: rgb(244, 180, 0);
}
.app .schedule-list--row > div {
  flex-grow: 1;
  line-height: 1.5rem;
  padding: 0 1rem;
}
.app .schedule-list--row div[class^=col-highlight] {
  padding-left: 0;
  padding-right: 0;
  width: 8px;
}
@media (min-width: 900px) {
  .app .schedule-list--row {
    flex-direction: row;
  }
  .app .schedule-list--row > div {
    display: block;
  }
  .app .schedule-list--row div[class^=col-highlight] {
    flex-grow: 0;
    padding: 0;
    width: 8px;
  }
}
.app .schedule-list--row .col-time {
  padding-bottom: 0.75rem;
}
.app .schedule-list--row .col-type {
  display: none;
}
.app .schedule-list--row .col-contact,
.app .schedule-list--row .col-misc > div {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 0.75rem;
}
.app .schedule-list--row .col-misc > div > span {
  margin-right: 0.5rem;
}
@media (min-width: 900px) {
  .app .schedule-list--heading .col-time,
  .app .schedule-list--row .col-time {
    max-width: 90px;
    padding-bottom: 0;
    padding-left: 1.5rem;
    text-align: center;
  }
  .app .schedule-list--row .col-time span {
    display: none;
  }
  .app .schedule-list--row .col-type {
    display: block;
  }
  .app .schedule-list--row .col-contact {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 0;
  }
  .app .schedule-list--row .col-contact > div {
    font-size: 0.875rem;
  }
  .app .schedule-list--row .col-misc > div {
    line-height: 1.25rem;
    padding-top: 0;
  }
  .app .schedule-list--heading .col-type,
  .app .schedule-list--row .col-type,
  .app .schedule-list--heading .col-venue,
  .app .schedule-list--row .col-venue,
  .app .schedule-list--heading .col-contact,
  .app .schedule-list--row .col-contact,
  .app .schedule-list--heading .col-misc,
  .app .schedule-list--row .col-misc {
    width: calc(25% - 15px);
  }
  .app .schedule-list--heading .col-type,
  .app .schedule-list--row .col-type,
  .app .schedule-list--heading .col-venue,
  .app .schedule-list--row .col-venue {
    min-width: 180px;
  }
}
.app .schedule-table {
  border-radius: 0;
}
.app .schedule-table th {
  border-top: 1px solid var(--bc-border-light-grey, #000);
  border-bottom: 1px solid var(--bc-border-light-grey, #000);
  font-size: 1rem !important;
  font-weight: 700 !important;
}
.app .schedule-table thead th:first-of-type {
  padding: 16px 0 !important;
  width: 8px !important;
}
.app .schedule-table tbody .rowlink:nth-child(odd) {
  background: rgba(158, 158, 158, 0.03);
}
.app .schedule-table tbody .rowlink td.row-highlight {
  padding: 16px 0 !important;
  width: 8px !important;
}
.app .schedule-table tbody .rowlink td.row-red {
  background-color: rgb(219, 68, 55);
}
.app .schedule-table tbody .rowlink td.row-purple {
  background-color: rgb(156, 39, 176);
}
.app .schedule-table tbody .rowlink td.row-yellow {
  background-color: rgb(244, 180, 0);
}
.app .schedule-table tbody .rowlink:hover {
  background: rgba(158, 158, 158, 0.1);
  cursor: pointer;
}
.app .schedule-table td {
  font-size: 1rem !important;
  vertical-align: top;
}
.app .notes-table thead th {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  font-weight: 700;
}
.app .breadcrumbs {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  padding: 1rem 0;
}
.app .card-notes {
  background-color: var(--bc-bg-lightest-grey, #FCFCFC);
  margin-top: 1.5rem;
  transition: all 300ms ease;
}
.app .card-notes:hover {
  background-color: var(--bc-persiangreen, #f7f7f7);
}
.app .card-notes:hover * {
  color: #fff;
}
.app .card-notes--heading {
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: capitalize;
}
.app .card-notes--body {
  font-size: 1rem;
  margin: 0 0 1rem;
}
.app .card-notes--meta {
  color: #6F6F6F;
  font-size: 0.875rem;
  font-weight: 500;
}
.app .card-notes--meta-user {
  color: var(--bc-persiangreen, #6F6F6F);
  text-align: left;
}
@media (min-width: 900px) {
  .app .card-notes--meta-user {
    text-align: right;
  }
}
.app .flex {
  display: flex;
}
.app .flex-col {
  flex-direction: column;
}
.app .flex-row {
  flex-direction: row;
}
.app .basis-1_12 {
  flex-basis: 8.3333333333%;
}
.app .basis-2_12 {
  flex-basis: 16.6666666667%;
}
.app .basis-3_12 {
  flex-basis: 25%;
}
.app .basis-4_12 {
  flex-basis: 33.3333333333%;
}
.app .basis-5_12 {
  flex-basis: 41.6666666667%;
}
.app .basis-6_12 {
  flex-basis: 50%;
}
.app .basis-7_12 {
  flex-basis: 58.3333333333%;
}
.app .basis-8_12 {
  flex-basis: 66.6666666667%;
}
.app .basis-9_12 {
  flex-basis: 75%;
}
.app .basis-10_12 {
  flex-basis: 83.3333333333%;
}
.app .basis-11_12 {
  flex-basis: 91.6666666667%;
}
.app .items-center {
  align-items: center;
}
.app .items-end {
  align-items: flex-end;
}
.app .items-start {
  align-items: flex-start;
}
.app .justify-content-around {
  justify-content: space-around;
}
.app .justify-content-between {
  justify-content: space-between;
}
.app .justify-content-center {
  justify-content: center;
}
.app .justify-content-end {
  justify-content: end;
}
.app .justify-content-evenly {
  justify-content: space-evenly;
}
.app .justify-content-start {
  justify-content: start;
}
.app .justify-content-stretch {
  justify-content: stretch;
}
.app .drawer {
  background-color: #FAF9F6;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
}
.app .drawer .header {
  align-items: center;
  border-radius: 0;
  display: flex;
  height: 64px;
  padding: 0 1.5rem;
  width: 100%;
}
.app .drawer footer {
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.175);
}
.app .font-smaller {
  font-size: 0.875rem;
}
.app .text-muted {
  color: rgba(0, 0, 0, 0.525);
}
.app .call-info > div {
  display: flex;
  gap: 8px;
}
.app .call-info > div > span:first-of-type {
  width: 100px;
}
.app .input-not-disabled {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.app .input-not-disabled input {
  color: rgba(0, 0, 0, 0.87);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
}
.app .assessments-wrapper {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  margin: 1rem 0 0;
  padding: 1rem 0;
  position: relative;
}
.app .assessments-wrapper:before,
.app .assessments-wrapper:after {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  bottom: 0;
  content: "";
  display: block;
  left: -1rem;
  height: 100%;
  position: absolute;
  top: 0;
  width: 1rem;
}
.app .assessments-wrapper:after {
  left: auto;
  right: -1rem;
}
@media (min-width: 900px) {
  .app .assessments-wrapper {
    border-radius: 4px;
    padding: 0.25rem;
  }
  .app .assessments-wrapper:before,
  .app .assessments-wrapper:after {
    content: none;
  }
}
.app .assessments-wrapper button {
  padding: 1rem;
}
.app .machine-info--wrapper {
  margin: 1rem 0 0;
  padding: 0 1rem;
}
@media (min-width: 900px) {
  .app .assessments-row:after {
    content: none;
    margin: 0;
  }
}
.app .sd-root-modern.sd-root-modern--full-container {
  overflow-x: visible;
}
.app .sd-root-modern form {
  position: relative;
  z-index: 0;
}
.app .sd-header__text .sd-title {
  font-size: 1.375rem;
  line-height: 1;
}
.app .sd-title.sd-container-modern__title {
  border-bottom: 2px solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  box-shadow: none;
}
.app .sd-root-modern--mobile .sd-body.sd-body--responsive, .app .sd-root-modern--mobile .sd-body.sd-body--static {
  padding: 1.5rem 0 0 0;
}
.app .sd-completedpage:after {
  height: calc(13 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
@media (min-width: 900px) {
  .app .sd-title.sd-container-modern__title {
    padding: 1.5rem;
  }
  .app .sd-body.sd-body--responsive {
    padding: 1.5rem;
  }
}
.app hr.page-divider {
  border: 0;
  background-color: var(--bc-border-light-grey, #000);
  display: block;
  height: 1px;
  margin: 4rem 0 3rem;
  overflow: visible;
  position: relative;
  width: 100%;
}
.app .text-capitalize {
  text-transform: capitalize;
}
.app .truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.app .message-bar__content {
  display: flex;
  flex-direction: row;
}
.app .message-bar__content > div {
  align-items: center;
  display: flex;
}
.app .message-bar__icon {
  padding-right: 1rem;
}
.app .message-bar__text {
  flex-grow: 1;
  font-size: inherit;
}
.app .message-bar__actions {
  font-size: inherit;
  flex-grow: 0;
}
@media (min-width: 1200px) {
  .app .message-bar__text {
    font-size: 1.125rem;
    line-height: 2rem;
  }
}
.app .offline-bar {
  align-items: center;
  background: linear-gradient(to right, #db4437, #ff314a);
  color: #fff;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 4rem;
  justify-items: center;
  position: relative;
  width: 100%;
}
.app .syncData-bar {
  align-items: center;
  background: linear-gradient(to right, #db4437, #ff314a);
  color: #fff;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 4rem;
  justify-items: center;
  position: relative;
  width: 100%;
}
.app .MuiDialog-container svg {
  color: #999;
}
.app .MuiDialogTitle-root {
  border-bottom: 2px solid var(--bc-persiangreen, #000);
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 28px;
  justify-content: space-between;
  padding: 12px;
}
.app .MuiDialogTitle-root div {
  padding-top: 2px;
}
.app .MuiDialogContent-root {
  background: #f7f8f9;
  padding: 12px !important;
}
.app .dialog-feedback .MuiDialog-paper {
  max-width: 396px;
  width: 100%;
}
.app .dialog-feedback .MuiDialogTitle-root {
  border-bottom: 2px solid var(--bc-persiangreen, #000);
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 28px;
  justify-content: space-between;
  padding: 12px;
}
.app .dialog-feedback .MuiDialogTitle-root div {
  padding-top: 2px;
}
.app .dialog-feedback .MuiDialogContent-root {
  background: #f7f8f9;
  padding: 12px;
  overflow-y: auto;
}
.app .dialog-feedback svg {
  color: #999;
}
.app .dialog-feedback .form-label {
  color: #212121;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding-bottom: 0.25rem;
}
.app #feedbackDescription,
.app #feedbackTitle,
.app #feedbackType {
  font-size: 0.875rem;
}
.app #feedbackDescription {
  line-height: 1.5em;
}
@media (min-width: 900px) {
  .app .dialog-feedback .MuiDialog-container {
    align-items: end;
    justify-content: end;
  }
}
.app .swap-machine-dialog .MuiDialog-paper {
  overflow-y: visible;
}
.app .swap-machine-dialog .MuiDialogActions-root {
  background-color: rgba(219, 68, 55, 0.1);
  border-top: 1px solid rgb(219, 68, 55);
  color: rgb(219, 68, 55);
  font-weight: 600;
  justify-content: space-between;
  padding: 12px;
}
.app .loading-fullscreen {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--bc-persiangreen, #000);
  display: flex;
  font-size: 4rem;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1900;
}
.app .loading-ellipsis {
  width: 100px;
}
.app .loading-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "…"; /* ascii code for the ellipsis character */
  width: 0px;
}
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}/*# sourceMappingURL=app_v1.css.map */
.feedback-sent-title{
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 1rem;
}
.feedback{
 border-top: solid 1px rgba(0,0,0,.02);
 border-bottom: solid 1px rgba(0,0,0,.02);
 margin-bottom: 1rem;
}
.feedback .date{
  font-size: .8rem;
  color: rgba(0,0,0,.7);
}
.SAF label{
  font-weight: bolder;
  margin-right: .8rem;
  color: rgba(0,0,0,1);
  margin-bottom: .5rem;
  text-transform: capitalize;
}
.SAF .MuiTypography-body1{
  border-bottom: solid 1px rgba(0,0,0,.1);
  margin-bottom: .5rem;;
}
.pt-1{
  padding-top: 1rem;
}
.pt-2{
  padding-top: 2rem;
}
.pt-3{
  padding-top: 3rem;
}
.pt-4{
  padding-top: 4rem;
}
.pb-1{
  padding-bottom: 1rem;
}
.pb-2{
  padding-bottom: 2rem;
}
.pb-3{
  padding-bottom: 3rem;
}
.pb-4{
  padding-bottom: 4rem;
}
.text-center{
  text-align: center;
}
/* Add to your stylesheet */
.gallery-image-area {
  position: relative;
  display: inline-block; /* Or as needed to fit your layout */
  width: 100%;
}

.gallery-image {
  cursor: pointer;
  width: 100%;
  height: auto;
  z-index: 1;
}
.gallery-image-overlay{
  position: absolute;
  right: 0;
  top: -0;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  cursor: pointer;
  z-index: 2;
}

.gallery-delete {
  position: absolute;
  top: -10;
  right: -10;
  color: red;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px; /* Adjust size as needed */
  height: 32px;
}


.note-meta{
  font-size: .85rem;
  color: rgba(0,0,0,.7);
}
.note-subject{
  font-weight: bolder;
  color:#000;
  text-transform: capitalize;
}
.schedule-day{
  font-size: 1.8rem !important;
  font-weight: 700;
  border-bottom: solid 1px rgba(0,0,0,.1);
  width: 100%;
}
.day-button{
  display: block !important;
  text-align: center;
  padding:.1rem;
}
.day-button div{
  padding-top:.1rem;
}
.day-button .day-jobs{
  border-top: solid 1px rgba(0,0,0,.1);
}

/*map markers */


.marker_red {
  border: 2px solid rgb(219, 68, 55);
  padding: 10px;
  border-radius: 8px;
}
.marker_purple {
  border: 2px solid  rgb(156, 39, 176);
  padding: 10px;
  border-radius: 8px;
}

.marker_yellow {
  border: 2px solid rgb(244, 180, 0);
  padding: 10px;
  border-radius: 8px;
}

.marker_blue {
  border: 2px solid  rgb(33, 150, 243);;
  padding: 10px;
  border-radius: 8px;
}
@media (max-width: 600px) { /* Adjust the max-width as needed for your mobile breakpoint */
  .app .schedule-page header .nav-schedule--grid {
    padding: 0; /* Remove padding that may be constraining the width */
  }

  .app .nav-schedule--item-mobile {
    flex-grow: 1; /* Ensure buttons expand to take up available space */
    margin: 0; /* Remove any margins that may be constraining the width */
  }

  .day-button {
    width: 100%; /* Set button width to 100% of its parent */
    box-sizing: border-box; /* Ensure padding is included in the width */
  }
}
@media (min-width: 601px) { /* Adjust min-width for your desktop breakpoint if necessary */
  .app .nav-schedule--grid {
    justify-content: space-between; /* Distribute space evenly between items */
  }

  .app .nav-schedule--item-mobile {
    flex: 1; /* Allow buttons to grow and take up available space */
    margin-right: 8px; /* Add margin for spacing between buttons */
  }

  .app .nav-schedule--item-mobile:last-child {
    margin-right: 0; /* Remove right margin from the last button */
  }
}
.dash-title{
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  border-bottom: #00A49B solid 1px;
  text-align: center;
  padding:.5rem;
}
.dash-footer{
  border-top:#00A49B solid 1px;
  text-align: right;
  margin-top: 1rem;
  padding:.5rem;
  font-size: .8rem;
}
.dash-item h1{
  font-size: 3rem;
  font-weight: 300;
  color:#00A49B;
  padding-bottom: .5rem;
  padding-top: 1rem;
  text-align: center;
  display: block;
}
.dash-item h2{
  font-size: 2.5rem;
  font-weight: 300;
  color:#00A49B;
  padding-bottom: .5rem;
  padding-top: 1rem;
  text-align: center;
  display: block;
}
.dash-item h3{
  font-size: 1.8rem;
  font-weight: 300;
  color:#00A49B;
  padding-bottom: .5rem;
  padding-top: 1rem;
  text-align: center;
  display: block;
}
.time-ranged{
  color:#00A49B;
  
}
.call-time-item{
  border-bottom: rgba(0,0,0,.04) inset 1px;
  text-align: center;
  padding:.25rem;
}
.call-type{
  margin-right: 1rem;
  font-weight: bolder;
}