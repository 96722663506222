/*
    Date Range: The way this is done on "Deliveries by Category" is AMAZING: https://preview.keenthemes.com/metronic8/demo9/dashboards/delivery.html
    Info below the header and before chart is perfect: https://themesbrand.com/velzon/html/material/index.html
*/
:root {
	--bc-font-sans-serif: 'Nunito', system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	--bc-persiangreen: #00A49B;
    --bc-persiangreen-rgb: 0,164,155;
	--bc-tarawera: #063852;
	--bc-border-light-grey: #E5E5E5;
	--bc-bg-lighter-grey: #F7F7F7;
	--bc-bg-lightest-grey: #FCFCFC;
}

/*
chart colors
['00A49B','#a6cee3','#1f78b4','#b2df8a','#33a02c','#fb9a99','#e31a1c','#fdbf6f','#ff7f00','#cab2d6','#6a3d9a','#ffff99','#b15928']
*/
body {
    
    margin: 0;
    padding: 0;
}

.d-none {
    display: none !important;
}

.p-1 {
    padding: 1rem;
}
.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.app {
    .bc-page--content {
        padding: 0 !important;
    }

    .dashboard {
        .section-title {
            align-items: center;
            display: flex;
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.75;
            margin: 2rem 0 0;
        }
        .section-title img {
            margin-right: .5rem;
            max-width: 1.25rem;
            opacity: .5;
            vertical-align: middle;
            width: 100%;
        }

        .bg-lightest-grey {
            background: var(--bc-bg-lightest-grey, #000);
        }

        .separator {
            border-top: 1px solid var(--bc-bg-lighter-grey, #000);
            display: block;
            font-size: 0;
            height: 0;
            margin: 0 0 1rem 0;
            position: relative;
            width: 100%;
        }

        .sticky-page-header {
            background-color: #fff;
            border-bottom: 1px solid var(--bc-border-light-grey, #000);
			display: block;
			height: 5.5rem;
            position: sticky;
			top: 4rem;
            z-index: 1000;
        }

		.filterContentWrapper {
			border-bottom: 1px solid var(--bc-bg-lighter-grey, #000);
		}

        .animate-bg-primary:hover .MuiCard-root {
            background-color: rgba(var(--bc-persiangreen-rgb),1);
            transition: background .2s ease-in;
        }
        
        .animate-bg-primary:hover * {
            color: #fff
        }

        .MuiCardHeader-root {
            border-bottom: 1px solid #e9edf4;
        }
        .animate-bg-primary:hover .MuiCardHeader-root {
            border-bottom-color: rgba(255,255,255,.3);
        }
        .MuiCardHeader-title {
            color: #6c757d;
            font-size: 1rem;
            font-weight: 500;
        }
        .MuiCardActions-root {
            border-top: 1px solid #e9edf4;
            color: #6c757d;
            font-size: .75rem;
        }
        .animate-bg-primary:hover .MuiCardActions-root {
            border-top-color: rgba(255,255,255,.3);
        }

        .card-stat-summary--total {
            font-size: 2rem;
            font-weight: 700;
            line-height: 1;
            margin: 0 0 .25rem;
        }
        
        .card-stat-summary--meta {
            color: #6c757d;
            display: flex;
            flex-direction: row;
            font-size: .875rem;
            line-height: 1.5rem
        }

        .MuiTableCell-head {
            color: #6c757d;
            font-size: 1rem;
            font-weight: 500;
        }
        .MuiTableCell-root {
            border-bottom: 1px solid #e9edf4;
            padding: .875rem;
        }


        .animate-bg-primary:hover .MuiCard-root {
            background-color: rgba(var(--bc-persiangreen-rgb),1);
            transition: background .2s ease-in;
        }
        
        .animate-bg-primary:hover * {
            color: #fff
        }
    
        .MuiCardHeader-root {
            border-bottom: 1px solid #e9edf4;
        }
        .animate-bg-primary:hover .MuiCardHeader-root {
            border-bottom-color: rgba(255,255,255,.3);
        }
        .MuiCardHeader-title {
            color: #6c757d;
            font-size: 1rem;
            font-weight: 500;
        }
        
        .card-stat-summary--total {
            font-size: 2rem;
            font-weight: 700;
            line-height: 1;
            margin: 0 0 .25rem;
        }
        
        .card-stat-summary--meta {
            color: #6c757d;
            display: flex;
            flex-direction: row;
            font-size: .875rem;
            line-height: 1.5rem
        }


        /* --------------------------------------------------------------------- */
        /*  COMPONENT - Cards
        /* --------------------------------------------------------------------- */

        .card {
            position: relative;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border-radius: .25rem;
            overflow: hidden;
        }
        .card-header {
            background-color: transparent;
            border: none;
            padding-bottom: 0;
        }
        .card-body {
            padding: .75rem 1.25rem;
        }

        /* --------------------------------------------------------------------- */

        .card.animate-bg-primary:hover {
            background-color: rgba(var(--bc-persiangreen-rgb), 1);
            transition: background .2s ease-in;
        }
        .card.animate-bg-primary:hover * {
            color: #fff;
        }

        /* --------------------------------------------------------------------- */

        .card-stat-summary {
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        }
        
        .card-stat-summary--title {
            color: #6c757d;
            font-size: 1rem;
        }

        .card-stat-summary--total-wrapper {
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: .75rem;
            position: relative;
        }
        .card-stat-summary--total {
            font-size: 2rem;
            font-weight: 700;
            line-height: 1;
            margin: .75rem 0 1.25rem;
        }
        .card-stat-summary--total-wrapper .card-stat-summary--total {
            margin-top: 0;
        }
        .card-stat-summary--total-subtitle {
            color: #6c757d;
            font-size: .875rem;
            font-weight: 400;
            line-height: 1.5rem;
        }

        .card-stat-summary--meta {
            color: #6c757d;
            display: flex;
            flex-direction: row;
            font-size: .875rem;
            line-height: 1.5rem;
        }
        .card-stat-summary--meta-timeline .text-persiangreen {
            color: var(--bc-persiangreen, #000);
            font-weight: 500;
        }
        .card-stat-summary--meta-badge {
            background-color: #f0f9f3;
            border-radius: .75rem;
            color: #4CAF50;
            font-weight: 700;
            padding: 0 .75rem;
        }
        .card-stat-summary--meta-badge.negative {
            background-color: #fceded;
            color: #F44336;
        }
        .animate-bg-primary:hover .card-stat-summary--meta-badge {
            background-color: rgba(255,255,255,.25);
            color: #fff;
        }

        /* --------------------------------------------------------------------- */

        .card-bg {
            display: none;
        }
        @media screen and (min-width: 600px){
            .card-bg {
                display: block;
                position: absolute;
                right: 1rem;
                top: 0;
            }
            .card-bg img {
                margin: 25% auto 0;
                max-height: 120px;
                max-width: 120px;
                opacity: .05;
                position: relative;
                width: 100%;
            }
            .animate-bg-primary:hover .card-bg img {
                filter: brightness(0) invert(1); /* Change color to white */
                opacity: .3;
            }
        }

        
    }
}

/* --------------------------------------------------------------------- */
/*  COMPONENT - Popover
/* --------------------------------------------------------------------- */

.popover-inner {
    display: flex;
    flex-direction: column;
    min-width: 300px;
}
.popover-header {
    align-items: center;
    border-bottom: 1px solid #e9edf4;
    color: #6c757d;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 500;
    padding: .5rem .5rem .5rem 1rem;
}
.popover-content {
    display: flex;
    flex-direction: column;
}
.popover--filter-item {
    padding: 1rem;
}
.popover--filter-item + .popover--filter-item {
    border-top: 1px solid #e9edf4;
}
.popover--filter-item label {
    color: #6c757d;
    display: flex;
    font-size: .875rem;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 .25rem .25rem;
}
.popover--filter-item label a {
    cursor: pointer;
    font-weight: 600;
}
.popover-actions {
    border-top: 1px solid #e9edf4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .5rem 1rem;
}